import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';
import { useTranslation } from 'react-i18next';
import { TopTagsFiltersComponentProps } from '@integration-frontends/integration/ui/attachment-selector/components/advanced-filters/components/top-tag-filters/top-tags-filters-component';
import React, { useState } from 'react';
import { FormSectionContent, FormSubSection } from '../../../common/form';
import {
  ALL_SELECTED_TAGS,
  ANY_SELECTED_TAGS,
  FILTERED_ITEMS_INCLUDE,
  SEARCH_TAGS_KEY,
  TOP_TAGS_KEY,
} from '@integration-frontends/integration/ui/attachment-selector/i18n';
import {
  CheckboxListContainer,
  CheckboxListItemContainer,
} from '@integration-frontends/integration/ui/attachment-selector/components/advanced-filters/common';
import {
  BFStandardCheckbox,
  BFStandardRadio,
  BFSearchInput,
  InputSize,
} from '@integration-frontends/common/ui';
import { hasTagFilter } from '@integration-frontends/integration/core/model';
import './smar-top-tags-filters-component.scss';
import '../../smar-advanced-filters.scss';
import { orderBy } from 'lodash';

export function SmarTopTagsFiltersComponent({
  searchParams,
  tags,
  onTagToggle,
  onOperatorChange,
}: TopTagsFiltersComponentProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const [search, setSearch] = useState('');

  const sortedTags = orderBy(tags, ['name']);

  return (
    <FormSubSection id="top-tags-filters" data-testid="top-tags-filters" title={t(TOP_TAGS_KEY)}>
      <FormSectionContent>
        <div className="filtered-items-options">
          <span>{t(FILTERED_ITEMS_INCLUDE)}:</span>
          <div className="options-list">
            <div>
              <BFStandardRadio
                name="operator"
                value="any-selected-tags"
                checked={searchParams?.tagFilters?.operator === 'OR'}
                label={t(ANY_SELECTED_TAGS)}
                onChange={() => onOperatorChange('OR')}
              />
            </div>
            <div>
              <BFStandardRadio
                name="operator"
                value="all-selected-tags"
                checked={searchParams?.tagFilters?.operator === 'AND'}
                label={t(ALL_SELECTED_TAGS)}
                onChange={() => onOperatorChange('AND')}
              />
            </div>
          </div>
        </div>

        <CheckboxListContainer className="checkbox-list">
          {sortedTags
            .filter(
              (tag) => search === '' || tag?.name?.toLowerCase().includes(search.toLowerCase()),
            )
            .slice(0, 10)
            .map((tag) => (
              <CheckboxListItemContainer key={tag.id}>
                <div
                  data-testid="tag-filter"
                  className="flex h-full cursor-pointer items-center"
                  onClick={() => onTagToggle(tag)}
                >
                  <div className="list-item h-full min-w-0">
                    <BFStandardCheckbox
                      value={tag.id}
                      checked={hasTagFilter(searchParams, tag)}
                      label={tag.name}
                      readOnly
                    />
                  </div>
                </div>
              </CheckboxListItemContainer>
            ))}
        </CheckboxListContainer>
        <BFSearchInput
          className="mt-sm w-full"
          data-testid="top-tags-search-filter"
          size={InputSize.Small}
          placeholder={t(SEARCH_TAGS_KEY)}
          onChange={(e) => setSearch(e.target.value)}
        />
      </FormSectionContent>
    </FormSubSection>
  );
}
