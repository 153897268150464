import { TopFileTypesFiltersComponentProps } from '@integration-frontends/integration/ui/attachment-selector/components/advanced-filters/components/top-file-type/top-file-types-filters-component';
import { useTranslation } from 'react-i18next';
import { INTEGRATION_COMMON_NAMESPACE } from '@integration-frontends/integration/ui/common/i18n';
import { FormSectionContent, FormSubSection } from '../../../common/form';
import { TOP_FILE_TYPES_KEY } from '@integration-frontends/integration/ui/attachment-selector/i18n';
import { BFStandardCheckbox } from '@integration-frontends/common/ui';
import {
  CheckboxListContainer,
  CheckboxListItemContainer,
} from '@integration-frontends/integration/ui/attachment-selector/components/advanced-filters/common';
import { hasFileTypeFilter } from '@integration-frontends/integration/core/model';
import React from 'react';
import '../../smar-advanced-filters.scss';
import { orderBy } from 'lodash';

export function SmarTopFileTypesComponent({
  fileTypeAggregates,
  fileTypeToggle,
  searchParams,
}: TopFileTypesFiltersComponentProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);

  const sortedFileTypeAggregates = orderBy(fileTypeAggregates, ['name']);

  return (
    <FormSubSection
      id="top-file-types-filters"
      data-testid="top-file-types-filters"
      title={t(TOP_FILE_TYPES_KEY)}
    >
      <FormSectionContent>
        <CheckboxListContainer className="checkbox-list">
          {sortedFileTypeAggregates.slice(0, 10).map((fileType) => (
            <CheckboxListItemContainer key={fileType.name}>
              <div
                data-testid="file-type-filter"
                className="flex cursor-pointer"
                onClick={() => fileTypeToggle(fileType.name)}
              >
                <div className="list-item min-w-0">
                  <BFStandardCheckbox
                    value={fileType.name}
                    checked={hasFileTypeFilter(searchParams, fileType.name)}
                    label={fileType.name}
                    readOnly
                  />
                </div>
              </div>
            </CheckboxListItemContainer>
          ))}
        </CheckboxListContainer>
      </FormSectionContent>
    </FormSubSection>
  );
}
